import React, {useEffect, useState} from 'react'
import "./Print.module.css"
import styles from './NewPrint.module.css';
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {FaFacebook, FaInstagram} from "react-icons/fa";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";
import {BsPersonCircle} from "react-icons/bs";
import {IoAirplane} from "react-icons/io5";
import LogoTicket from "../../../assets/images/airtiketa-logo.png"
import FooterImageTicket from "../../../assets/images/ezgif-7f920dd9fedc8a.gif"
import ConfigurationServices from "../../services/configuration.services";
import SwissFlag from "../../../assets/images/swiss.png"
import GermanyFlag from "../../../assets/images/germany.jpg"
import KosovaFlag from "../../../assets/images/kosova.png"
type iProps = {
    reservation: any
}

const NewPrintInvoice = React.forwardRef(({
                                              reservation
                                          }: iProps, ref: any) => {
    const travelers = reservation.travelers.filter((t: any) => t.status);
    const traveler = travelers.find((t: any) => t.status);
    const [initialImageUrl, setInitialImageUrl] = useState("");
    const account = useSelector((state: AppState) => state.account.account.account);

    const footerLogo = () => {
        if (account?.logoImageUrl != null) {
            return <img src={FooterImageTicket} alt="Logo"/>
        } else {
            return <img src={FooterImageTicket} alt="Logo"
                        style={{width: '570px', height: '250px', position: 'sticky'}}/>
        }
    };

    useEffect(() => {
        ConfigurationServices.getConfigurationLogo()
            .then((response) => {
                setInitialImageUrl(response.logoImageUrl);
            })
            .catch((error) => {
                console.error("Error fetching initial image:", error);
            });

    }, [initialImageUrl]);

    const returnLogoImage = () => {
        if (initialImageUrl) {
            return <div className={styles.logoContainer}>
                <img src={initialImageUrl} alt="Logo" className={styles.logo}/>
            </div>
        } else {
            return <div className={styles.logoContainer}>
                <img src={LogoTicket} alt="Logo" className={styles.logo}/>
            </div>
        }
    }

    return (
        <div id="printContent" ref={ref} className={styles.main}>
            <div className={styles.flex}>
                <div className={styles.logoContainer}>
                    {returnLogoImage()}
                </div>
                <div className={styles.icons}>
                    <div className={styles.iconFlex}>
                        <FaFacebook style={{color: '#EC0C31', fontSize: '25px', marginRight: '25px'}}/>
                        <FaInstagram style={{color: '#EC0C31', fontSize: '25px', marginRight: '25px'}}/>
                        <FontAwesomeIcon icon={faGlobe} size="2xl" style={{color: '#EC0C31',}}/>
                    </div>
                </div>
                <div className={styles.accordionRow}>
                    <div className={styles.accordionHeader}>
                        <div className={styles.accordionLabels}>
                            <span style={{
                                color: 'white',
                                fontSize: 18,
                                fontWeight: "bold",
                                paddingRight: "420px"
                            }}>Flight confirmation code:</span>
                            <span style={{
                                color: 'white',
                                fontSize: 20,
                                fontWeight: "bold",
                            }}>{reservation.confirmationCode}</span>
                        </div>
                    </div>
                </div>
                <div style={{
                    marginTop: '10px',
                    float: 'left',
                    padding: '0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginLeft: '-530px'
                }}>
                    <BsPersonCircle style={{color: '#EC0C31', fontSize: '25px', marginRight: '10px'}}/>
                    <span style={{fontSize: 18, padding: '0px'}}>Passenger info</span>
                </div>
                <div style={{marginTop: '10px', width: '700px'}}>
                    <table style={{width: '700px'}}>
                        <thead>
                        <tr className={styles.title}>
                            <td style={{margin: '8px', padding: '8px'}}>Passenger</td>
                            <td>Route</td>
                            <td>Checked-in bag</td>
                            <td>Seat</td>
                        </tr>
                        </thead>
                        <tbody>
                        {travelers.map((traveler: any, index: any) => (
                            <tr className={styles.tableRowFields}>
                                <td style={{margin: '8px', padding: '8px'}}>
                                    {traveler.ageCategory === 'ADULT' ?
                                        traveler.gender === 'MALE' ? 'MR' : 'MRS' : ''}
                                    {traveler.ageCategory === 'CHILD' && 'CHD'}
                                    {traveler.ageCategory === 'INF' && 'INF'}. {traveler?.name} {traveler?.surname}
                                </td>
                                <td>
                                    {traveler?.departureFlight?.departureAirport?.code} - {traveler?.departureFlight?.arrivalAirport.code}
                                    {traveler?.returningFlight && (
                                        <span>
                                            {' / '} {traveler?.returningFlight?.departureAirport?.code} - {traveler?.returningFlight?.arrivalAirport.code}
                                         </span>
                                    )}
                                </td>
                                <td>
                                    {traveler?.departureFlight?.gepack} kg
                                    / {traveler?.departureFlight?.gepackINF} kg inf
                                </td>
                                <td>
                                    -
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div style={{
                    marginTop: '10px',
                    float: 'left',
                    padding: '0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginLeft: '-530px'
                }}>
                    <IoAirplane style={{color: '#EC0C31', fontSize: '25px', marginRight: '10px'}}/>
                    <span style={{fontSize: 18, padding: '0px'}}>Flight details</span>
                </div>
                <div className={styles.secondAccordionRow}>
                    <div className={styles.accordionHeader}>
                        <div>
                            <div className={styles.accordionLabels}>
                                <span
                                    style={{
                                        fontSize: '17px', color: '#EC0C31',
                                        marginTop: '10px', marginBottom: '5px'
                                    }}>
                                    GOING OUT</span>
                                <span
                                    style={{
                                        fontSize: '17px', color: '#EC0C31',
                                        marginTop: '10px', marginBottom: '5px'
                                    }}>
                                    {traveler.departureFlight?.number}</span>
                            </div>
                            <div className={styles.accordionLabels} style={{borderTop: '1px solid #DEDEDE'}}>
                                <span style={{paddingRight: "531px", marginTop: '5px'}}>Departs from: </span>
                                <span style={{marginTop: '10px'}}>Arrives to:</span>
                            </div>
                            <div className={styles.accordionLabels}>
                              <span className={styles.leftLabel}>
                                <span><b>{traveler.departureFlight?.departureAirport.name} ({traveler?.departureFlight?.departureAirport.code})</b></span>
                              </span>
                                <span className={styles.centerLabel}>
                                <IoAirplane fontSize='25px' color='#EC0C31' style={{marginRight: '0.50rem'}}/>
                              </span>
                                <span className={styles.rightLabel}>
                                <span><b>{traveler.departureFlight?.arrivalAirport.name} ({traveler?.departureFlight?.arrivalAirport.code})</b></span>
                              </span>
                            </div>
                            <div className={styles.accordionLabels}>
                                <span style={{paddingRight: "430px", marginTop: '1px'}}>
                                    {traveler.departureFlight?.dateOfDeparture} {traveler?.departureFlight?.timeOfDeparture}
                                </span>
                                <span style={{marginTop: '1px'}}>
                                    {traveler.departureFlight?.dateOfDeparture} {traveler?.departureFlight?.timeOfArrival}
                                </span>
                            </div>
                        </div>
                    </div>
                    {traveler.returningFlight && (
                        <div className={styles.accordionHeader} style={{marginBottom: '10px'}}>
                            <div>
                                <div className={styles.accordionLabels}>
                                <span
                                    style={{
                                        fontSize: '17px', color: '#EC0C31',
                                        marginTop: '10px', marginBottom: '5px'
                                    }}>
                                    COMING BACK</span>
                                    <span
                                        style={{
                                            fontSize: '17px', color: '#EC0C31',
                                            marginTop: '10px', marginBottom: '5px'
                                        }}>
                                    {traveler.departureFlight?.number}</span>
                                </div>
                                <div className={styles.accordionLabels} style={{borderTop: '1px solid #DEDEDE'}}>
                                    <span style={{paddingRight: "531px", marginTop: '5px'}}>Departs from: </span>
                                    <span style={{marginTop: '10px'}}>Arrives to:</span>
                                </div>
                                <div className={styles.accordionLabels}>
                              <span className={styles.leftLabel}>
                                <span><b>{traveler.returningFlight?.departureAirport.name} ({traveler?.returningFlight?.departureAirport.code})</b></span>
                              </span>
                                    <span className={styles.centerLabel}>
                                <IoAirplane fontSize='25px' color='#EC0C31' style={{marginRight: '0.50rem'}}/>
                              </span>
                                    <span className={styles.rightLabel}>
                                <span><b>{traveler.returningFlight?.arrivalAirport.name} ({traveler?.returningFlight?.arrivalAirport.code})</b></span>
                              </span>
                                </div>
                                <div className={styles.accordionLabels}>
                                <span style={{paddingRight: "430px", marginTop: '1px'}}>
                                    {traveler.returningFlight?.dateOfDeparture} {traveler?.returningFlight?.timeOfDeparture}
                                </span>
                                    <span style={{marginTop: '1px'}}>
                                    {traveler.returningFlight?.dateOfDeparture} {traveler?.returningFlight?.timeOfArrival}
                                </span>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
                <div className={styles.logoContainer} style={{ marginTop: '20px', fontSize: '18px', display: 'flex', gap: '20px', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <img src={SwissFlag} alt="Swiss Flag" width="24" height="16" />
                        <span>+41 44 586 36 66</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <img src={GermanyFlag} alt="German Flag" width="24" height="16" />
                        <span>+49 211 38533933</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <img src={KosovaFlag} alt="Kosovo Flag" width="24" height="16" />
                        <span>+383 48 282 284</span>
                    </div>
                </div>

                <div>
                    <div className={styles.iconFlex} style={{marginBottom: '10px', fontSize: '18px',}}>
                        <span>
info@airtiketa.eu
</span>
                    </div>
                </div>

                <div>
                    {footerLogo()}
                </div>
            </div>
        </div>
    )
        ;
});

export default NewPrintInvoice;